import React, { Component } from "react";
import Fade from 'react-reveal/Fade';
import './fifth.css';
// import { ReactComponent as YourSvg } from '../../../../public/assets/images/test.svg';

class Fifth extends Component{
    render(){
        return(
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--45 align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-12">
                            <Fade left>
                                <div className="about-inner inner">
                                    { this.props.text }
                                    { this.props.buttons? <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{display: 'grid', justifyContent: ''}}>
                                            <div>
                                                <div className="button-group mt--50">
                                                    <a 
                                                        className="btn-default" 
                                                        href="link.html" 
                                                        style={{pointerEvents: 'none', cursor:'default', width: '95%'}}
                                                    >
                                                        Join the waitlist
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{display: 'grid', justifyContent: ''}}>
                                            <div className="about-us-list">
                                                <div className="button-group mt--50">
                                                    <a 
                                                        className="btn-default" 
                                                        href="link.html" 
                                                        style={{pointerEvents: 'none', cursor:'default'}}
                                                    >
                                                        Seller
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                             {/* <Link to="Contact" spy={true} smooth={true} href="#Contact"><h5 className="title">Contact Sales</h5></Link> */}
                                            </div>
                                        </div>
                                    </div> : "" }                                  
                                </div>
                                </Fade>
                            </div>
                                <div   className={ "col-lg-7 col-md-14"}>
                                    <div className="stonks"> 
                                            <Fade top> <img className="w-150" src={'/assets/images/stonks.png'} alt="stonks"/> </Fade>
                                    </div>
                                    
                                    <div className="sell"> 
                                        <Fade right> <img className="w-150" src={'/assets/images/sell_button.png'} alt="sell"/> </Fade>
                                    </div>
                                    <div className="buy">
                                        <Fade left> <img className="w-150" src={'/assets/images/buy_button.png'} alt="buy"/> </Fade>
                                    </div>
                                
                                    <div className="QR">
                                        <Fade bottom>  <img className="w-150" src={'/assets/images/QR.png'} alt="qr"/> </Fade>
                                    </div>                                    
                                    
                                    <div className="jordanshoe">
                                        <img className="w-150" src={'/assets/images/jordan.png'} alt="jordanshoe"/>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Fifth;