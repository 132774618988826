import React, { Component } from "react";
import Fade from 'react-reveal/Fade';
import './fourth.css';
// import { ReactComponent as YourSvg } from '../../../../public/assets/images/test.svg';

class Fourth extends Component{
    render(){
        return(
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--45 align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-12">
                            <Fade left>
                                <div className="about-inner inner">
                                    { this.props.text }
                                    { this.props.buttons? <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{display: 'grid', justifyContent: ''}}>
                                            <div>
                                                <div className="button-group mt--50">
                                                    <a 
                                                        className="btn-default" 
                                                        href="link.html" 
                                                        style={{pointerEvents: 'none', cursor:'default', width: '95%'}}
                                                    >
                                                        Join the waitlist
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{display: 'grid', justifyContent: ''}}>
                                            <div className="about-us-list">
                                                <div className="button-group mt--50">
                                                    <a 
                                                        className="btn-default" 
                                                        href="link.html" 
                                                        style={{pointerEvents: 'none', cursor:'default'}}
                                                    >
                                                        Seller
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                             {/* <Link to="Contact" spy={true} smooth={true} href="#Contact"><h5 className="title">Contact Sales</h5></Link> */}
                                            </div>
                                        </div>
                                    </div> : "" }                                  
                                </div>
                                </Fade>
                            </div>
                                <div   className={ "col-lg-7 col-md-14"}>
                                
                                    <div className="checkout1"> 
                                            <Fade left> <img className="w-150" src={'/assets/images/checkout_1.png'} alt="Checkout1"/> </Fade>
                                    </div>
                                    
                                    <div className="checkout2"> 
                                        <Fade right> <img className="w-150" src={'/assets/images/checkout_2.png'} alt="Checkout2"/> </Fade>
                                    </div>
                                    
                                    <div className="checkout3">
                                        <Fade left> <img className="w-150" src={'/assets/images/checkout_3.png'} alt="Checkout3"/> </Fade>
                                    </div>
                                
                                    <div className="checkout4">
                                        <Fade right>  <img className="w-150" src={'/assets/images/checkout_4.png'} alt="Checkout4"/> </Fade>
                                    </div>
                                
                                </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Fourth;