// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from "./App";

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import * as serviceWorker from './serviceWorker';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyDV0hJmRnBd6QKjYuCDU5d7fEpMFHnzzwU",
    authDomain: "asap-website-d6d85.firebaseapp.com",
    projectId: "asap-website-d6d85",
    storageBucket: "asap-website-d6d85.appspot.com",
    messagingSenderId: "695428341658",
    appId: "1:695428341658:web:b97ed8abc82c370658e423",
    measurementId: "G-L7G0RPXTPT"
};

// Initialize Firebase
initializeApp(firebaseConfig);

class Root extends Component{
    render(){
        return(
            <App />
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();







