import React, { Component } from "react";
import Fade from 'react-reveal/Fade';
import './first.css';
// import { ReactComponent as YourSvg } from '../../../../public/assets/images/test.svg';

class First extends Component{

    constructor() {
        super();
        this.state = {join: false, isBuyer: true, isSelected: false, joined: false};
    }

    changeToBuyerSeller = () => {
        this.setState({join: true })
    }

    isNotBuyer = () => {
        this.setState({isSelected: true })
    }

    changeToEmail = () => {
        this.setState({isSelected: true })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({joined: true })
    }

    render(){
        return(
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--45 align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-12">
                            <Fade left>
                                <div className="about-inner inner" style={{ width: '105%'}}>
                                    { this.props.text }
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{maxWidth: '100%', flex: '0 0 100%' }}>
                                            { !this.state.join ? 
                                                <div className="mt--40">
                                                    <button 
                                                        className="btn-default" 
                                                        onClick={this.changeToBuyerSeller}
                                                        style={{ width: '55%'}}
                                                    >
                                                        Join the waitlist
                                                    </button>
                                                </div> :
                                                !this.state.isSelected ? 
                                                <Fade clear> <div style={{marginTop: '30px'}}>
                                                    <p style={{ fontWeight: 700, fontSize: '20px', marginBottom: '1%'}} > Are you a buyer or a seller? </p>
                                                    <button 
                                                        className="buybtn btn-default" 
                                                        onClick={this.changeToEmail}
                                                        style={{ marginRight: '5%'}}
                                                    >
                                                        Buyer
                                                    </button>
                                                    <button 
                                                        className="sellbtn btn-default" 
                                                        onClick={this.changeToEmail}
                                                        style={{ marginTop: '5%'}}
                                                    >
                                                        Seller
                                                    </button>
                                                </div> </Fade>: 
                                                !this.state.joined ? 
                                                <Fade clear> 
                                                    <div className="form-wrapper" style={{width: '65%', marginTop: '20px'}}>
                                                        <form action="" onSubmit={this.handleSubmit}>
                                                            <div className="rn-form-group">
                                                                <input type="email" name="email" placeholder="Your best email" required="required"></input>
                                                            </div>
                                                            <div className="rn-form-group">
                                                                <button style={{width: '85%', marginTop: '20px'}} className="btn-default"  name="submit" id="mc-embedded-subscribe">Join the waitlist</button>
                                                            </div>
                                                        </form>
                                                    </div>  
                                                </Fade> :
                                                <div> 
                                                    <Fade clear> <p style={{ fontWeight: 700, fontSize: '20px', marginTop: '15px', color: 'green'}}> You have successfully subscribed. </p> </Fade> 
                                                </div>
                                            }
                                        </div>
                                    </div>                                       
                                </div>
                                </Fade>
                            </div>
                            <Fade right>
                                <div   className={ "col-lg-7 col-md-14"}>
                                        <div className="phone"> 
                                            <img className="w-150" src={'/assets/images/home_1_phone.png'} alt="Iphone"/>
                                        </div>
                                        <div className="playstation"> 
                                            <img className="w-150" src={'/assets/images/home_1_desc.png'} alt="Iphone"/>
                                        </div>
                                        <div className="sold">
                                            <img className="w-150" src={'/assets/images/home_1_sold.png'} alt="Sold"/>
                                        </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
        )
    }
}
export default First;