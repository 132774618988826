import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

// import SliderOne from "../component/slider/SliderOne";
import First from "../component/HomeLayout/homeOne/first";
import Second from "../component/HomeLayout/homeOne/second";
import Third from "../component/HomeLayout/homeOne/third";
import Fourth from "../component/HomeLayout/homeOne/fourth";
import Fifth from "../component/HomeLayout/homeOne/fifth";
import Helmet from "../component/common/Helmet";

class MainDemo extends Component{
    render(){
        return(
            <div> 
                <Helmet pageTitle="ASAP – Local Marketplace" />
                <Header headertransparent="header--transparent" logoname="logo.png" />

                <div className="about-area bg_color--5" style={{paddingTop: '8%', paddingBottom: '0'}}>
                    <First
                        id={1}
                        text={ <div className="section-title">
                                    <p className="description" style={{ color: "#C5C5C5"}}> LOCAL MARKETPLACE APP </p>
                                    <h1 className="title" > {"Buy and Sell Locally Again."} </h1>
                                        <p  style={{marginBottom: 0, color:'black'}}> {`Start buying and selling locally the quick,`}</p>
                                        <p style={{marginBottom: 0, color:'black'}}> {` easy and secure way.`}</p>
                                    </div> }
                        buttons={true}
                        linkToImage= "/assets/images/home1_group2.png"
                    />
                </div>

                <div className="about-area bg_color--5" style={{paddingTop: '8%', paddingBottom: '0'}}>
                    <Second
                        id={2}
                        text={ <div className="section-title" style={{marginBottom: '10%'}}>
                                    <h1 className="title"  style={{margin: 0}}> {"Sell "} <span style={{color:'#7A57DD'}}> Smart </span> </h1>
                                    <h1 className="title" > {"Shop "} <span style={{color:'#16951B'}}> Safe </span> </h1>
                                        <p style={{marginBottom: 0, color:'black', textAlign: 'justify'}}> {`We pre-populate listings for some of the most popular products out there. We can also suggest a competitive price that fits your local market, so you know you’re not getting taken advantage of.`}</p>
                                    </div> }
                        buttons={false}
                        linkToImage= "/assets/images/home_2_phone.png"
                    />
                </div>

                <div className="about-area bg_color--5" style={{paddingTop: '8%', paddingBottom: '0'}}>
                <Third
                        id={3}
                        text={ <div className="section-title" style={{marginBottom: '10%'}}>
                                    <h1 className="title" style={{margin: 0}}> {"Don’t get robbed."} </h1>
                                    <h1 className="title" > {"Verified sellers and buyers only."} </h1>
                                        <p style={{marginBottom: 0, color:'black', textAlign: 'justify'}}> {`With verified sellers and buyers, you can focus on your transaction instead of worrying about watching your back. Every user goes through I.D verification to ensure your utmost safety.`}</p>
                                    </div> }
                        buttons={false}
                        linkToImage= "/assets/images/home_3_phone.png"
                    />
                </div>

                <div className="about-area bg_color--5" style={{paddingTop: '8%', paddingBottom: '0'}}>
                <Fourth
                        id={4}
                        text={ <div className="section-title" style={{marginBottom: '10%'}}>
                                    <h1 className="title" > {"Pay and get paid instantly, don’t wait weeks."} </h1>
                                        <p style={{marginBottom: 0, color:'black', textAlign: 'justify'}}> {`When you purchase a product, we hold your money until your meetup. Once both parties confirm the transaction is complete, we automatically release the payments to the seller.`}</p>
                                        {/* <span className="subtitle">Join the botting Dynasty</span> */}
                                        {/* <h2 className="title" >Secure Limited Releases Product with   <span style={{color:'#ff66a1'}}> One Click* </span> </h2>
                                        <p className="description" style={{textAlign: 'justify'}}>Our automated software speeds up your checkout process, allowing you to enjoy limited release products without paying resell prices.</p> */}
                                    </div> }
                        buttons={false}
                        linkToImage= "/assets/images/home4_group.png"
                    />
                </div>

                {/* <div className="about-area bg_color--5" style={{paddingTop: '8%', paddingBottom: '0'}}>
                <AboutTwo
                        id={5}
                        text={ <div className="section-title" style={{marginBottom: '10%'}}>
                                    <h1 className="title" > {"Skip the small talk. "} </h1>
                                    <h1 className="title" > <span style={{color:'green'}}> Buy now </span> {"or"} <span style={{color:'red'}}> set a bid. </span></h1>
                                        <p style={{marginBottom: 0, color:'black', textAlign: 'justify'}}> {`We know you hate hagglers. No more lowball messages, no more bumping your posts. Simply click buy and set the meetup.`}</p>
                                    </div> }
                        buttons={false}
                        linkToImage= "/assets/images/home5_group.png"
                    />
                </div> */}

                <div className="about-area bg_color--5" style={{paddingTop: '8%', paddingBottom: '0'}}>
                <Fifth
                        id={6}
                        text={ <div className="section-title" style={{marginBottom: '10%'}}>
                                    <h1 className="title" > {"Find the lowest price right away"} </h1>
                                        <p style={{marginBottom: 0, color:'black', textAlign: 'justify'}}> {`Don’t spend time browsing through multiple listings to find the best price from a legit seller. We automatically show you the lowest price in your area from our list of verified users.`}</p>
                                        </div> }
                        buttons={false}
                        linkToImage= "/assets/images/home3_group.png"
                    />
                    <div style={{paddingTop: '8%', paddingBottom: '3%', display: 'grid', justifyContent: 'center', textAlign: 'center'}}>
                        <h2 style={{marginBottom: 0}}>Join the waitlist for early access. </h2>
                        <p style={{marginBottom: 0}}>After you're on the waitlist, you'll get a referral link to share. Be first in line for </p>
                        <p style={{marginBottom: 0}}>early access by referring others. The more you refer, the higher up in </p>
                        <p style={{marginBottom: 0}}>line you’ll go. </p>
                        <div className="row" style={{justifyContent: 'center'}}>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{marginTop: 0}}>
                                <div className="about-us-list">
                                    <div className="button-group mt--50" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                        <button 
                                            className="btn-default" 
                                            href="" 
                                            style={{width: '80%'}}
                                        >
                                            Join the waitlist
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <div id="Contact"><Footer /></div>
            </div>
        )
    }
}
export default MainDemo;