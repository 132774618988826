import React from "react";
// import { getApps } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";

import PageScrollTop from './component/PageScrollTop';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';

import error404 from "./dark/error404";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';

export default function App() {
    // const firebaseApp = getApps()[0];
    // const firebaseAnalytics = getAnalytics();
    return (
    <div>
        <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkMainDemo}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
    </div>
    );
}
