import React, { Component } from "react";
import Fade from 'react-reveal/Fade';
import './second.css';
// import { ReactComponent as YourSvg } from '../../../../public/assets/images/test.svg';

class Second extends Component{
    render(){
        return(
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--45 align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-12">
                            <Fade left>
                                <div className="about-inner inner">
                                    { this.props.text }
                                    { this.props.buttons? <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{display: 'grid', justifyContent: ''}}>
                                            <div>
                                                <div className="button-group mt--50">
                                                    <a 
                                                        className="btn-default" 
                                                        href="link.html" 
                                                        style={{pointerEvents: 'none', cursor:'default', width: '95%'}}
                                                    >
                                                        Join the waitlist
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12" style={{display: 'grid', justifyContent: ''}}>
                                            <div className="about-us-list">
                                                <div className="button-group mt--50">
                                                    <a 
                                                        className="btn-default" 
                                                        href="link.html" 
                                                        style={{pointerEvents: 'none', cursor:'default'}}
                                                    >
                                                        Seller
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                             {/* <Link to="Contact" spy={true} smooth={true} href="#Contact"><h5 className="title">Contact Sales</h5></Link> */}
                                            </div>
                                        </div>
                                    </div> : "" }                                  
                                </div>
                                </Fade>
                            </div>
                            <Fade right>
                                <div   className={ "col-lg-7 col-md-14"}>
                                        <div className="phone2"> 
                                            <img className="w-150" src={'/assets/images/home_2_phone.png'} alt="Iphone"/>
                                        </div>
                                        <div className="nvidia"> 
                                            <img className="w-150" src={'/assets/images/home_2_nvidia.png'} alt="Nvidia"/>
                                        </div>
                                        <div className="jordan">
                                            <img className="w-150" src={'/assets/images/home_2_jordan.png'} alt="Jordan"/>
                                        </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Second;